<template>
  <div class="page-content" style="background-color: #f7f8fa">
    <div
      class="search-content"
      style="
        background-color: #f7f8fa;
        box-shadow: 0px 0px 0px 0px;
        height: 100%;
      "
    >
      <svg
        width="366"
        height="300"
        viewBox="0 0 366 300"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.1"
          d="M250.936 44.4026C191.362 43.9424 142.192 89.111 136.797 147.086C122.539 132.439 102.579 123.237 80.4598 123.083C36.6847 122.777 0.924827 157.822 0.616552 201.381L0 279.141L135.333 280.138L158.53 280.291L362.84 281.825L363.765 158.436C364.304 95.9361 313.747 44.8627 250.936 44.4026Z"
          fill="#B3B3B3"
        />
        <path
          opacity="0.3"
          d="M61.9741 37.194C59.3538 37.194 56.9647 38.0375 55.0379 39.4179C55.1921 38.651 55.2691 37.8842 55.2691 37.1173C55.3462 30.7523 50.1826 25.5376 43.7088 25.4609C37.2351 25.3842 32.0715 30.5223 31.9944 36.9639C31.9944 37.194 31.9944 37.4241 31.9944 37.6541C30.7613 37.194 29.374 36.8872 27.9868 36.8872C21.5901 36.8106 16.3494 41.9486 16.2724 48.3903C16.1953 54.6786 21.2048 59.8166 27.4473 60.0467L61.82 60.2767C68.2167 60.3534 73.4574 55.2154 73.5344 48.7737C73.6115 42.332 68.3708 37.2707 61.9741 37.194Z"
          fill="#B3B3B3"
        />
        <path
          opacity="0.3"
          d="M354.437 11.7339C351.817 11.7339 349.428 12.5775 347.501 13.9579C347.655 13.191 347.732 12.4241 347.732 11.6573C347.809 5.29225 342.645 0.0775343 336.172 0.000847372C329.698 -0.0758395 324.534 5.06218 324.457 11.5039C324.457 11.7339 324.457 11.964 324.457 12.1941C323.224 11.7339 321.837 11.4272 320.45 11.4272C314.053 11.3505 308.812 16.4885 308.735 22.9302C308.658 29.2186 313.668 34.3566 319.91 34.5866L354.283 34.8167C360.68 34.8934 365.92 29.7554 365.997 23.3137C366.074 16.872 360.834 11.8106 354.437 11.7339Z"
          fill="#B3B3B3"
        />
        <g opacity="0.1">
          <path
            opacity="0.1"
            d="M277.76 244.325C276.141 244.325 274.908 245.552 274.908 247.163C274.908 248.313 275.525 249.233 276.45 249.693L276.064 299.617H278.762L279.147 249.54C279.995 249.08 280.534 248.16 280.534 247.163C280.611 245.552 279.378 244.402 277.76 244.325Z"
            fill="#B3B3B3"
          />
          <path
            opacity="0.1"
            d="M335.867 247.546C335.867 246.089 334.634 244.786 333.016 244.709C331.397 244.709 330.164 245.936 330.164 247.546C330.164 248.62 330.781 249.54 331.628 250L331.243 300H333.94L334.326 250C335.251 249.54 335.79 248.62 335.867 247.546Z"
            fill="#B3B3B3"
          />
        </g>
        <path
          opacity="0.1"
          d="M268.507 253.75L268.412 266.404L339.547 266.931L339.641 254.278L268.507 253.75Z"
          fill="#B3B3B3"
        />
        <path
          opacity="0.1"
          d="M276.595 253.758L276.441 253.988L268.965 266.411H268.426L268.503 253.758H276.595Z"
          fill="#B3B3B3"
        />
        <path
          opacity="0.1"
          d="M294.566 253.911L286.936 266.565L277.303 266.488L280.308 261.426L284.778 253.834L294.566 253.911Z"
          fill="#B3B3B3"
        />
        <path
          opacity="0.1"
          d="M312.513 254.065L304.884 266.641L295.25 266.564L302.726 253.988L312.513 254.065Z"
          fill="#B3B3B3"
        />
        <path
          opacity="0.1"
          d="M330.461 254.218L322.908 266.795L313.197 266.718L320.75 254.141L330.461 254.218Z"
          fill="#B3B3B3"
        />
        <path
          opacity="0.1"
          d="M339.644 254.218L339.566 266.948L331.166 266.871L338.719 254.218H339.644Z"
          fill="#B3B3B3"
        />
        <path
          opacity="0.1"
          d="M268.344 273.321L268.25 285.974L339.384 286.504L339.479 273.85L268.344 273.321Z"
          fill="#B3B3B3"
        />
        <path
          opacity="0.1"
          d="M276.451 273.313L276.296 273.543L268.821 285.966H268.281L268.358 273.313H276.451Z"
          fill="#B3B3B3"
        />
        <path
          opacity="0.1"
          d="M294.406 273.466L286.776 286.12L277.143 286.043L280.148 280.982L284.618 273.39L294.406 273.466Z"
          fill="#B3B3B3"
        />
        <path
          opacity="0.1"
          d="M312.353 273.62L304.723 286.196L295.09 286.12L302.565 273.543L312.353 273.62Z"
          fill="#B3B3B3"
        />
        <path
          opacity="0.1"
          d="M330.318 273.773L322.842 286.35L313.055 286.273L320.607 273.696L330.318 273.773Z"
          fill="#B3B3B3"
        />
        <path
          opacity="0.1"
          d="M339.481 273.85L339.404 286.503L331.004 286.426L338.557 273.85H339.481Z"
          fill="#B3B3B3"
        />
        <path
          opacity="0.1"
          d="M339.57 266.948C339.57 266.948 339.57 266.871 339.57 266.718V266.028C339.57 265.491 339.57 264.647 339.57 263.574C339.57 261.427 339.647 258.282 339.647 254.218L339.724 254.295L268.59 253.911L268.667 253.835C268.667 258.129 268.59 262.423 268.59 266.488L268.512 266.411L319.224 266.871L334.252 266.948H338.337H334.252L319.224 266.871L268.512 266.641H268.281L268.358 253.758H268.435L339.57 254.371H339.647V254.448C339.647 258.512 339.57 261.657 339.57 263.804C339.57 264.954 339.57 265.721 339.57 266.258V266.795V266.948Z"
          fill="#B3B3B3"
        />
        <path
          opacity="0.1"
          d="M339.41 286.503C339.41 286.503 339.41 286.426 339.41 286.273V285.583C339.41 285.046 339.41 284.202 339.41 283.129C339.41 280.982 339.487 277.837 339.487 273.773L339.564 273.85L268.429 273.466L268.506 273.39C268.506 277.684 268.429 281.979 268.429 286.043L268.352 285.966L319.064 286.426L334.092 286.503H338.177H334.092L319.064 286.426L268.352 286.196H268.121L268.198 273.39H268.275L339.41 274.003H339.487V274.08C339.487 278.144 339.41 281.288 339.41 283.436C339.41 284.586 339.41 285.353 339.41 285.89V286.426C339.41 286.35 339.41 286.503 339.41 286.503Z"
          fill="#B3B3B3"
        />
        <path
          opacity="0.35"
          d="M92.7214 196.626H79.6967V225.69H53.5704V196.626H4.55469V177.378L45.247 109.356H79.6967V175.537H92.7214V196.626ZM53.5704 175.537V158.513C53.5704 151.151 54.3411 140.031 54.6494 132.669H54.0328C51.0272 139.264 47.8673 145.936 44.6304 152.531L31.2976 175.537H53.5704ZM101.738 166.948C101.738 126.918 118.385 107.209 143.124 107.209C167.786 107.209 184.664 127.224 184.664 166.948C184.664 206.979 167.863 227.991 143.124 227.991C118.385 227.914 101.738 206.979 101.738 166.948ZM158.153 166.948C158.153 135.2 151.371 128.605 143.047 128.605C134.724 128.605 128.096 135.2 128.096 166.948C128.096 198.85 134.724 206.365 143.047 206.365C151.371 206.365 158.153 198.85 158.153 166.948ZM281.077 196.626H268.053V225.69H241.926V196.626H192.911V177.378L233.603 109.356H268.053V175.537H281.077V196.626ZM241.926 175.537V158.513C241.926 151.151 242.697 140.031 243.005 132.669H242.389C239.383 139.264 236.223 145.936 232.986 152.531L219.654 175.537H241.926Z"
          fill="#B3B3B3"
        />
      </svg>
      <h1>Sorry!</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {};
  },
};
</script>
<style>
.search-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 380px;
  background: #fff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  color: #282828;
  margin-top: 36px;
  width: 100%;
}
</style>
